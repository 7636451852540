import { useState } from 'react';
import {
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';

import THSarabunNew from './assets/fonts/THSarabunNew/THSarabunNew.ttf';
import THSarabunNewBold from './assets/fonts/THSarabunNew/THSarabunNewBold.ttf';
import THSarabunNewItalic from './assets/fonts/THSarabunNew/THSarabunNewItalic.ttf';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Register font
Font.register({
  family: 'THSarabunNew',
  format: 'truetype',
  fonts: [
    {
      src: THSarabunNew,
      fontWeight: 400,
    },
    {
      src: THSarabunNewBold,
      fontWeight: 700,
    },
    {
      src: THSarabunNewItalic,
      fontStyle: 'italic',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  pdfViewer: {
    minHeight: '100vh',
    width: '100%',
  },
  page: {
    fontFamily: 'THSarabunNew',
    fontSize: 14,
    padding: 50,
  },
  section: {
    marginBottom: 10,
  },
  headerTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  p: {
    textAlign: 'justify',
    textIndent: 16,
  },
  subTitle: {
    fontWeight: 'bold',
    marginBottom: 4,
  },
  input: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
  },
});

// Create Document Component
const MyDocument = ({ contactInfo }) => (
  <Document pageMode="fullScreen" title="PEC contract form">
    <Page size="A4" className="flex-1" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.headerTitle}>เอกสารแนบท้ายหมายเลข 1</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.p}>
          บริษัท พีอีซี ทราเวล คอนเนค จำกัด โดย นายฉัตรชัย ทิพย์เมืองเก่า
          กรรมการผู้มีอำนาจกระทำการแทนบริษัท สำนักงานตั้งอยู่เลขที่ 60/44 หมู่ที่ 2
          ตำบลวิชิต อำเภอเมืองภูเก็ต จังหวัดภูเก็ต โทรศัพท์ 088-776-2426 อีเมล
          partner@jooys.co ซึ่งต่อไปในสัญญานี้จะเรียกว่า "ผู้ให้บริการ" ฝ่ายหนึ่ง กับ
        </Text>
        <Text style={styles.p}>
          PEC Travel Connect Company Limited, by Mr. Chatchai Tipmuangkao, authorized
          director, has its head office located at 60/44 Moo.2, Vichit Sub-district, Muang
          Phuket District, Phuket province. Tel 088-766-2426. Email is partner@jooys.co
          hereinafter referred to as "Service Provider" of one part and
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.p}>
          <Text style={[styles.input, { textIndent: 16 }]}>
            {contactInfo.fullName || contactInfo.companyName}
          </Text>
          {contactInfo.idNumber && (
            <>
              <Text> บัตรประจำตัวประชาชน /Passport No. </Text>
              <Text style={styles.input}>{contactInfo.idNumber}</Text>
            </>
          )}
          {contactInfo.companyTaxId && (
            <>
              <Text> เลขทะเบียนนิติบุคคล/Juristic ID. </Text>
              <Text style={styles.input}>{contactInfo.companyTaxId}</Text>
            </>
          )}
          {contactInfo.age && (
            <>
              <Text> อายุ/Age </Text>
              <Text style={styles.input}>{contactInfo.age}</Text>
              <Text> ปี</Text>
            </>
          )}
          {contactInfo.nationality && (
            <>
              <Text> สัญชาติ/Nationality </Text>
              <Text style={styles.input}>{contactInfo.nationality}</Text>
            </>
          )}
          {contactInfo.address && (
            <>
              <Text> ที่อยู่/Address </Text>
              <Text style={styles.input}>{contactInfo.address}</Text>
            </>
          )}
          {contactInfo.telephone && (
            <>
              <Text> โทรศัพท์/Telephone </Text>
              <Text style={styles.input}>{contactInfo.telephone}</Text>
            </>
          )}
          {contactInfo.emailAddress && (
            <>
              <Text> อีเมล/Email </Text>
              <Text style={styles.input}>{contactInfo.emailAddress}</Text>
            </>
          )}
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.p}>
          ซึ่งต่อไปในสัญญาฉบับนี้จะเรียกว่า "ร้านค้า" อีกฝ่ายหนึ่ง
        </Text>
        <Text style={styles.p}>hereinafter referred to as "Shop" of one part.</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.subTitle}>ข้อมูลร้านค้า/Shop Information</Text>
        <Text style={styles.p}>
          <Text style={{ textIndent: 16 }}>ชื่อร้านค้า/Shop name </Text>
          <Text style={[styles.input]}>{contactInfo.shopName}</Text>
          {contactInfo.shopAddress && (
            <>
              <Text> ที่อยู่/Shop Address </Text>
              <Text style={styles.input}>{contactInfo.shopAddress}</Text>
            </>
          )}
          {contactInfo.shopPhone && (
            <>
              <Text>, โทรศัพท์ / Telephone </Text>
              <Text style={styles.input}>{contactInfo.shopPhone}</Text>
            </>
          )}
          {contactInfo.shopLine && (
            <>
              <Text>, Line ID </Text>
              <Text style={styles.input}>{contactInfo.shopLine}</Text>
            </>
          )}
          {contactInfo.shopFacebook && (
            <>
              <Text>, Facebook </Text>
              <Text style={styles.input}>{contactInfo.shopFacebook}</Text>
            </>
          )}
          {contactInfo.shopInstagram && (
            <>
              <Text>, Instagram </Text>
              <Text style={styles.input}>{contactInfo.shopInstagram}</Text>
            </>
          )}
        </Text>
        <Text style={styles.p}>
          <Text style={{ textIndent: 16 }}>ช่องทางการรับชำระเงิน/Payment Method </Text>
          <Text style={styles.input}>
            {contactInfo.shopAcceptCash ? ' เงินสด/Cash' : ''}
            {contactInfo.shopAcceptTransfer ? ' โอน/Transfer' : ''}
            {contactInfo.shopAcceptCard ? ' บัตรเครดิต/Credit card' : ''}
          </Text>
        </Text>
        <Text style={styles.p}>
          <Text style={{ textIndent: 16 }}>ประเภทสินค้า/Type of Business </Text>
          <Text style={styles.input}>{contactInfo.shopCategory}</Text>
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.p}>
          ในระยะเวลาสัญญาร้านค้าจะแจ้งหรือนำเสนอโปรโมชั่น
          หรือนำเสนอคูปองอย่างต่อเนื่องตลอดสัญญาขั้นต่ำ1โปรโมชั่นหรือ 1 คูปอง
          โดยสามารถกำหนดระยะเวลาที่มีผลต่อโปรโมชั่น หรือคูปองได้
          โดยมีระยะเวลามีผลของโปรโมชั่นขั้นต่ำ 7 วัน
          และโปรโมชั่นหรือคูปองใดๆจะสามารถขอแจ้งลบได้เมื่อพ้นระยะเวลา 7 วันไปแล้ว
        </Text>
        <Text style={styles.p}>
          During the contract period, the Shop will notify or offer promotions. or present
          coupons continuously throughout the contract with a minimum of 1 promotion or 1
          coupon. You can set the effective period for a promotion or coupon, with a
          minimum promotion period of 7 days, and any promotion or coupon will be able to
          request deletion after the expiration of the period 7 days later.
        </Text>
      </View>
    </Page>
  </Document>
);

function App() {
  const [contractType, setContractType] = useState('individual');
  const [showDocument, setShowDocument] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    fullName: '',
    age: '',
    nationality: '',
    idNumber: '',
    address: '',
    telephone: '',
    emailAddress: '',

    companyName: '',
    companyTaxId: '',

    shopName: '',
    shopAddress: '',
    shopPhone: '',
    shopLine: '',
    shopFacebook: '',
    shopInstagram: '',
    shopCategory: '',
    shopAcceptCash: false,
    shopAcceptTransfer: false,
    shopAcceptCard: false,
  });
  // const [shopAcceptCash, setShopAcceptCash] = useState(false);
  // const [shopAcceptTransfer, setShopAcceptTransfer] = useState(false);
  // const [shopAcceptCard, setShopAcceptCard] = useState(false);

  const handleChange = (event) => {
    setContactInfo({
      ...contactInfo,
      [event.target.name]: event.target.value,
    });
    setShowDocument(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowDocument(true);
    console.log(contactInfo);
  };

  const shopCategories = [
    {
      name: 'Activity',
      value: 'activity',
    },
    {
      name: 'Art & Craft ',
      value: 'Art & Craft ',
    },
    {
      name: 'Beauty',
      value: 'Beauty',
    },
    {
      name: 'Cafe',
      value: 'Cafe',
    },
    {
      name: 'Clinic',
      value: 'Clinic',
    },
    {
      name: 'Culture',
      value: 'Culture',
    },
    {
      name: 'Entertainment',
      value: 'Entertainment',
    },
    {
      name: 'Event',
      value: 'Event',
    },
    {
      name: 'Farm & Zoo & Aquarium',
      value: 'Farm & Zoo & Aquarium',
    },
    {
      name: 'Gym',
      value: 'Gym',
    },
    {
      name: 'Museum & Gallery',
      value: 'Museum & Gallery',
    },
    {
      name: 'Nightclub',
      value: 'Nightclub',
    },
    {
      name: 'Pharmacy',
      value: 'Pharmacy',
    },
    {
      name: 'Restaurant',
      value: 'Restaurant',
    },
    {
      name: 'Salon',
      value: 'Salon',
    },
    {
      name: 'Shopping',
      value: 'Shopping',
    },
    {
      name: 'Spa',
      value: 'Spa',
    },
    {
      name: 'Sport',
      value: 'Sport',
    },
    {
      name: 'Theme and Water park',
      value: 'Theme and Water park',
    },
    {
      name: 'Tours & Local Guide',
      value: 'Tours & Local Guide',
    },
    {
      name: 'Wellness Program',
      value: 'Wellness Program',
    },
  ];

  return (
    <div className="max-w-5xl py-8 px-4 mx-auto w-full text-gray-700">
      <h2 className="text-2xl lg:text-3xl mb-4 text-gray-900">Create a contract</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-6 mb-8">
          <div className="col-span-6 sm:col-span-3">
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-6">
                <div className="flex flex-col">
                  <span className="text-gray-500 mb-1">Contract type</span>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      placeholder=""
                      name="contractType"
                      checked={contractType === 'individual'}
                      onChange={(e) => setContractType('individual')}
                    />
                    <span className="ml-2">Individual</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      placeholder=""
                      name="contractType"
                      onChange={(e) => setContractType('company')}
                    />
                    <span className="ml-2">Company</span>
                  </label>
                </div>
              </div>

              {contractType === 'company' && (
                <>
                  <label className="col-span-6">
                    <span className="text-gray-700">Company Name</span>
                    <input
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder=""
                      name="companyName"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6">
                    <span className="text-gray-700">Tax ID</span>
                    <input
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder=""
                      name="companyTaxId"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6">
                    <span className="text-gray-700">Address</span>
                    <textarea
                      name="address"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    ></textarea>
                  </label>
                  <label className="col-span-6 md:col-span-3">
                    <span className="text-gray-700">Telephone</span>
                    <input
                      type="tel"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder=""
                      name="telephone"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6 md:col-span-3">
                    <span className="text-gray-700">Email</span>
                    <input
                      type="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      name="emailAddress"
                      onChange={handleChange}
                    />
                  </label>
                </>
              )}
              {contractType === 'individual' && (
                <>
                  <label className="col-span-6">
                    <span className="text-gray-700">Full Name</span>
                    <input
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder=""
                      name="fullName"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6">
                    <span className="text-gray-700">ID Card no.</span>
                    <input
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      name="idNumber"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6 sm:col-span-3">
                    <span className="text-gray-700">Age</span>
                    <input
                      type="number"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder=""
                      name="age"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6 sm:col-span-3">
                    <span className="text-gray-700">Nationality</span>
                    <input
                      type="text"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder=""
                      name="nationality"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6">
                    <span className="text-gray-700">Address</span>
                    <textarea
                      name="address"
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    ></textarea>
                  </label>
                  <label className="col-span-6 sm:col-span-3">
                    <span className="text-gray-700">Telephone</span>
                    <input
                      type="tel"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder=""
                      name="telephone"
                      onChange={handleChange}
                    />
                  </label>
                  <label className="col-span-6 sm:col-span-3">
                    <span className="text-gray-700">Email</span>
                    <input
                      type="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      name="emailAddress"
                      onChange={handleChange}
                    />
                  </label>
                </>
              )}
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-6">
                <h2 className="text-xl font-semibold">Shop Detail</h2>
              </div>
              <label className="col-span-6">
                <span className="text-gray-700">Category</span>
                <select
                  name="shopCategory"
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {shopCategories.map((category, index) => (
                    <option key={index} value={category.value}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </label>
              <label className="col-span-6">
                <span className="text-gray-700">Name</span>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder=""
                  name="shopName"
                  onChange={handleChange}
                />
              </label>
              <label className="col-span-6">
                <span className="text-gray-700">Address</span>
                <textarea
                  name="shopAddress"
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                ></textarea>
              </label>
              <label className="col-span-6 sm:col-span-3">
                <span className="text-gray-700">Telephone</span>
                <input
                  type="tel"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder=""
                  name="shopPhone"
                  onChange={handleChange}
                />
              </label>
              <label className="col-span-6 sm:col-span-3">
                <span className="text-gray-700">Line</span>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder=""
                  name="shopLine"
                  onChange={handleChange}
                />
              </label>
              <label className="col-span-6 sm:col-span-3">
                <span className="text-gray-700">Facebook</span>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder=""
                  name="shopFacebook"
                  onChange={handleChange}
                />
              </label>
              <label className="col-span-6 sm:col-span-3">
                <span className="text-gray-700">Instagram</span>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder=""
                  name="shopInstagram"
                  onChange={handleChange}
                />
              </label>
              <div className="col-span-6">
                <span className="text-gray-500">Payment Method</span>
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300text-indigo-600shadow-smfocus:border-indigo-300focus:ringfocus:ring-offset-0focus:ring-indigo-200focus:ring-opacity-50"
                      value="true"
                      name="shopAcceptCash"
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: e.target.checked,
                          },
                        })
                      }
                    />
                    <span className="ml-2">เงินสด/Cash</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300text-indigo-600shadow-smfocus:border-indigo-300focus:ringfocus:ring-offset-0focus:ring-indigo-200focus:ring-opacity-50"
                      name="shopAcceptTransfer"
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: e.target.checked,
                          },
                        })
                      }
                    />
                    <span className="ml-2">โอน/Transfer</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300text-indigo-600shadow-smfocus:border-indigo-300focus:ringfocus:ring-offset-0focus:ring-indigo-200focus:ring-opacity-50"
                      name="shopAcceptCard"
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: e.target.checked,
                          },
                        })
                      }
                    />
                    <span className="ml-2">บัตรเครดิต/Credit card</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-6 md:col-span-3 md:col-end-7 text-center md:text-right space-x-2">
            <button
              type="submit"
              className="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Preview
            </button>
            <PDFDownloadLink
              document={<MyDocument contactInfo={contactInfo} />}
              fileName="pec-contract-form.pdf"
            >
              {({ blob, url, loading, error }) => (
                <button
                  type="button"
                  className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-indigo-600 dark:hover:bg-indigo-700 focus:outline-none dark:focus:ring-indigo-800"
                >
                  Download now!
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </form>
      {showDocument && (
        <PDFViewer style={styles.pdfViewer}>
          <MyDocument contactInfo={contactInfo} />
        </PDFViewer>
      )}
    </div>
  );
}

export default App;
